import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get All For Approvals
const adm_approvalService_GetAllForApproval = (data) =>
  axios.get(domainAPI + '/api/admin/staging-request/for-approvals', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      page: data.page,
    },
  });

// Update staging request - update item as approved
const adm_approvalService_UpdateSRItemsSetApproved = (data) =>
  axios.post(
    domainAPI + `/api/admin/request-item/${data.id}/approve-delivery`,
    {},
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// // Update staging request - update item as disapproved
// const adm_approvalService_UpdateSRItemsSetUnApproved = (data) =>
//   axios.post(
//     domainAPI + `/api/admin/request-item/${data.id}/disapprove-delivery`,
//     {
//       remarks: data.remarks,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${data.token}`,
//       },
//     }
//   );

// Update staging request - update item as disapproved
const adm_approvalService_UpdateSRItemsSetUnApproved = (data) =>
axios.post(
  domainAPI + `/api/admin/request-item/disapprove-delivery`,
  {
    items: data.items,
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

export {
  adm_approvalService_GetAllForApproval,
  adm_approvalService_UpdateSRItemsSetApproved,
  adm_approvalService_UpdateSRItemsSetUnApproved,
};
